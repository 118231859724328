import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { EngagementDto } from '../../api/dto/engagement.dto';
import { getEngagementsCall } from '../../api/functions/engagements.functions';

export const useEngagements = (
  tenantId?: string
): UseQueryResult<EngagementDto[], Error> => {
  const fetchAllEngagements = async (): Promise<
    EngagementDto[] | undefined
  > => {
    if (!tenantId) {
      throw new Error(`No tenantId`);
    }
    const response = await getEngagementsCall();

    return response?.data?.filter((engagement: EngagementDto) =>
      tenantId ? engagement.tenantId === tenantId : true
    );
  };

  return useQuery({
    queryKey: ['fetchAllEngagements', tenantId],
    queryFn: fetchAllEngagements,
    enabled: !!tenantId, // Disable query until tenantId is available
  });
};
