import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EngagementDto } from '../../api/dto/engagement.dto';
import { getEngagementCall } from '../../api/functions/engagements.functions';
import { CustomToast } from '../../components/CustomToast';
import lang from '../../utils/lang';

type UseEngagementType = {
  engagement: EngagementDto;
  fetchData: () => Promise<void>;
};

export const useEngagement = (): UseEngagementType => {
  const { engagementId } = useParams();
  const [engagement, setEngagement] = useState({} as EngagementDto);
  const { addToast } = CustomToast();

  async function fetchData() {
    try {
      if (!engagementId) {
        throw new Error(`No engagement id in URL params`);
      }
      const response = await getEngagementCall(engagementId);
      if (response) {
        setEngagement(response.data);
      }
    } catch (error) {
      console.error(error);
      const toastMessage =
        error instanceof Error ? error.message : lang.errors.default;
      const toastStatus = 'error';
      addToast(toastMessage, toastStatus);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagementId]);

  return { engagement, fetchData };
};
