import React from 'react';
import { useParams } from 'react-router-dom';
import { Center, FormControl, Text, UseModalProps } from '@chakra-ui/react';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';

import {
  CreateEngagementDto,
  ENGAGEMENT_STATUSES,
} from '../../../api/dto/engagement.dto';
import { createEngagementCall } from '../../../api/functions/engagements.functions';
import { CustomToast } from '../../../components/CustomToast';
import { CustomDateInput } from '../../../components/Form/CustomDateInput';
import { CustomNumberInput } from '../../../components/Form/CustomNumberInput';
import { CustomSelectInput } from '../../../components/Form/CustomSelectInput';
import { CustomTextInput } from '../../../components/Form/CustomTextInput';
import GenericButton from '../../../components/GenericButton';
import { useEngagements } from '../../../hooks/useEngagements';
import lang from '../../../utils/lang';

import 'react-datepicker/dist/react-datepicker.css';

// Define the form validation schema using Yup
const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  displayTitle: yup.string(),
  surveySparrowId: yup.string(),
  startDate: yup.date(),
  endDate: yup
    .date()
    .min(
      yup.ref('startDate'),
      'End date must be greater than or equal to the start date'
    ),
  responseCountTarget: yup.number(),
});

export function CreateEngagementForm({
  onClose,
}: {
  onClose: UseModalProps['onClose'];
}) {
  const { tenantId } = useParams();

  const { refetch } = useEngagements(tenantId);

  const initialValues: CreateEngagementDto = {
    name: '',
    displayTitle: '',
    resultsStatus: ENGAGEMENT_STATUSES['ADMIN_ONLY'],
    startDate: undefined,
    endDate: undefined,
    completedDate: undefined,
    responseCountTarget: 0,
    responseCount: 0,
    surveySparrowId: '',
    tenantId: tenantId ?? '',
  };

  // Handle notification toast (error, success...)
  const { addToast } = CustomToast();

  const onSubmit = async (values: CreateEngagementDto) => {
    try {
      if (!values.tenantId) {
        throw new Error('There is no tenant');
      }
      const response = await createEngagementCall(values);
      if (response?.status === 201) {
        await refetch();
        onClose();
        const toastMessage = `The engagement '${values.name}' has been created!`;
        const toastStatus = 'success';
        addToast(toastMessage, toastStatus);
      }
    } catch (error) {
      console.error(error);
      const toastMessage =
        error instanceof Error ? error.message : lang.errors.default;
      const toastStatus = 'error';
      addToast(toastMessage, toastStatus);
    }
  };

  const formik: FormikProps<CreateEngagementDto> =
    useFormik<CreateEngagementDto>({
      initialValues,
      validationSchema,
      onSubmit,
    });

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* Engagement Name */}
      <CustomTextInput
        name="name"
        label="name"
        placeholder="The name of your engagement"
        helperMessage=""
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isMandatory={true}
      />
      {formik.touched.name && formik.errors.name && (
        <Text as="sub" color="red">
          {formik.errors.name}
        </Text>
      )}
      {/* Engagement displayTitle */}
      <CustomTextInput
        name="displayTitle"
        label="display title"
        placeholder="The title you want to display"
        helperMessage=""
        value={formik.values.displayTitle}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isMandatory={false}
      />
      {formik.touched.displayTitle && formik.errors.displayTitle && (
        <Text as="sub" color="red">
          {formik.errors.displayTitle}
        </Text>
      )}

      {/* Engagement surveySparrowId */}
      <CustomTextInput
        name="surveySparrowId"
        label="SurveySparrow ID"
        placeholder="The Survey sparrow ID linked to this engagement"
        helperMessage=""
        value={formik.values.surveySparrowId ?? ''}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isMandatory={false}
      />
      {formik.touched.surveySparrowId && formik.errors.surveySparrowId && (
        <Text as="sub" color="red">
          {formik.errors.surveySparrowId}
        </Text>
      )}

      {/* Engagement status */}
      <CustomSelectInput
        name="status"
        label="status"
        value={formik.values.resultsStatus}
        options={ENGAGEMENT_STATUSES}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isMandatory={true}
      />
      {formik.touched.resultsStatus && formik.errors.resultsStatus && (
        <Text as="sub" color="red">
          {formik.errors.resultsStatus}
        </Text>
      )}

      <FormControl padding={3}>
        {/* Engagement startDate */}
        <CustomDateInput
          name="startDate"
          label="Start date"
          placeholder="Pick a date or leave it blank"
          value={formik.values.startDate}
          minDate={undefined}
          isMandatory={true}
          onChange={(date) => formik.setFieldValue('startDate', date)}
          onBlur={formik.handleBlur}
        />

        {formik.touched.startDate && formik.errors.startDate && (
          <Text as="sub" color="red">
            {formik.errors.startDate}
          </Text>
        )}
        {/* Engagement endDate */}
        <CustomDateInput
          name="endDate"
          label="End date"
          placeholder="Pick a date or leave it blank"
          minDate={formik.values.startDate}
          value={formik.values.endDate}
          isMandatory={true}
          onChange={(date) => formik.setFieldValue('endDate', date)}
          onBlur={formik.handleBlur}
        />

        {formik.touched.endDate && formik.errors.endDate && (
          <Text as="sub" color="red">
            {formik.errors.endDate}
          </Text>
        )}
      </FormControl>

      {/* Engagement responseCountTarget */}
      <CustomNumberInput
        name="responseCountTarget"
        label="Target number of respondents"
        value={formik.values.responseCountTarget}
        isMandatory={false}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />

      {formik.touched.responseCountTarget &&
        formik.errors.responseCountTarget && (
          <Text as="sub" color="red">
            {formik.errors.responseCountTarget}
          </Text>
        )}
      <Center>
        {' '}
        <GenericButton
          type="submit"
          text="Submit"
          onClick={formik.handleSubmit}
        />
      </Center>
    </form>
  );
}
