import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Heading, Stack } from '@chakra-ui/react';
import { ComparableEngagements } from '@keyops-cep/api-client';

import { refreshSurveyCall } from '../../api/functions/engagements.functions';
import { apiClient } from '../../api/swagger-codegen-api-config';
import { CustomBreadcrumb } from '../../components/CustomBreadcrumb';
import { CustomToast } from '../../components/CustomToast';
import Loading from '../../components/Loading';
import { useEngagement } from '../../hooks/useEngagement';
import useEngagementFiles from '../../hooks/useEngagementFiles';
import { useTenant } from '../../hooks/useTenants';
import { ROOT, TENANTED_ENGAGEMENTS_PAGE } from '../../utils/internal-routes';
import lang from '../../utils/lang';

import { ComparableEngagementsCard } from './components/comparableEngagementsCard';
import { EngagementActionButtons } from './components/engagementActionButtons';
import { EngagementFileCard } from './components/engagementFileListCard';
import { EngagementSettingsCard } from './components/engagementSettingsCard';
import { EngagementStatusCard } from './components/engagementStatusCard';
import { UpdateEngagementForm } from './components/updateEngagementForm';

const Engagement: React.FunctionComponent = () => {
  const tenant = useTenant();
  const { engagement, fetchData } = useEngagement();
  const { engagementFiles, fetchEngagementFiles } = useEngagementFiles(
    engagement.id
  );
  const [hasClickedRefreshButton, setHasClickedRefreshButton] = useState(false);
  const [hasStartedInterval, setHasStartedInterval] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const refreshStatusRef = useRef(engagement.refreshStatus);
  const { addToast } = CustomToast();
  const navigate = useNavigate();

  useEffect(() => {
    refreshStatusRef.current = engagement.refreshStatus;
  }, [engagement.refreshStatus]);

  useEffect(() => {
    const fetchDataWithInterval = async () => {
      const interval = setInterval(async () => {
        await fetchData();
        if (refreshStatusRef.current !== 'IN_PROGRESS') {
          clearInterval(interval);
          setLoading(false);
          setHasClickedRefreshButton(false);
          setHasStartedInterval(false);

          if (refreshStatusRef.current === 'COMPLETED') {
            // List again the comparable engagements as the refresh-survey just finished and may have updated the list
            handleListComparableEngagement(engagement?.id);

            addToast(lang.engagement.single.survey.refresh.success, 'success');
          } else {
            addToast(lang.engagement.single.survey.refresh.failure, 'error');
          }
        }
      }, 2000);

      return () => clearInterval(interval);
    };

    if (hasClickedRefreshButton && !hasStartedInterval) {
      setHasStartedInterval(true);
      fetchDataWithInterval();
    }
    // eslint-disable-next-line
  }, [addToast, fetchData, hasClickedRefreshButton, hasStartedInterval]);

  const handleRefreshSurvey = async () => {
    setLoading(true);

    await fetchData();
    if (engagement.refreshStatus === 'IN_PROGRESS') {
      addToast(lang.engagement.single.survey.refresh.inProgress, 'error');
      setLoading(false);
      return;
    }

    await refreshSurveyCall(engagement.id);
    await fetchData();
    setHasClickedRefreshButton(true);
  };

  const [comparableEngagements, setComparableEngagements] = useState<
    ComparableEngagements[]
  >([]);

  const handleListComparableEngagement = async (engagementId: string) => {
    try {
      const result =
        await apiClient.comparableEngagementsApi.comparableEngagementsControllerGetAll(
          engagementId
        );
      if (result && result.data.length > 0) {
        const comparableEngagements = result.data;

        setComparableEngagements(comparableEngagements);
      }
    } catch (error) {
      addToast((error as Error)?.message, `warning`, 'Warning');
    }
  };

  useEffect(() => {
    if (engagement?.id && refreshStatusRef.current === 'COMPLETED')
      handleListComparableEngagement(engagement.id);
    // eslint-disable-next-line
  }, [engagement?.id]);

  const tenantName = tenant?.name
    ? tenant?.name.slice(0, 1)[0].toLocaleUpperCase() +
      tenant?.name.slice(1, tenant?.name.length)
    : '';

  const getRenderedView = () => {
    return (
      <Container maxWidth="1600px" width="100%" margin="auto">
        <CustomBreadcrumb
          list={[
            { text: 'Home', link: ROOT },
            {
              text: `${tenantName} Engagements`,
              link: TENANTED_ENGAGEMENTS_PAGE.replace(':tenantId', tenant.id),
            },
            { text: engagement?.name, link: '' },
          ]}
        />
        <Stack
          spacing={3}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Heading
            as="h1"
            fontSize={24}
            lineHeight={'32px'}
            textAlign="left"
            margin={5}
          >
            {engagement?.name}
          </Heading>

          <EngagementActionButtons
            engagement={engagement}
            tenant={tenant}
            isLoading={isLoading}
            handleRefreshSurvey={handleRefreshSurvey}
            fetchEngagementFiles={fetchEngagementFiles}
            navigate={navigate}
          />
        </Stack>
        <Stack
          spacing={3}
          marginTop={8}
          marginRight={0}
          padding={3}
          justifyContent="space-between"
          direction="row"
        >
          <Stack spacing={6} direction="column">
            <UpdateEngagementForm
              engagement={engagement}
              isDisabled={isLoading}
              navigate={navigate}
            />
            <EngagementSettingsCard
              engagement={engagement}
              fetchEngagement={fetchData}
              isDisabled={isLoading}
            />
            {comparableEngagements && comparableEngagements?.length > 0 && (
              <ComparableEngagementsCard
                comparableEngagements={comparableEngagements}
                currentEngagement={engagement}
                handleListComparableEngagement={handleListComparableEngagement}
              />
            )}
          </Stack>
          <Stack spacing={6} direction="column">
            <EngagementStatusCard
              engagement={engagement}
              fetchEngagement={fetchData}
              isDisabled={isLoading}
            />
            <EngagementFileCard
              engagementFiles={engagementFiles}
              fetchEngagementFiles={fetchEngagementFiles}
            ></EngagementFileCard>
          </Stack>
        </Stack>
      </Container>
    );
  };

  if (!tenant || !engagement) {
    return <Loading />;
  }

  return getRenderedView();
};

export default Engagement;
