import React from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseModalProps,
} from '@chakra-ui/react';

import lang from '../../../utils/lang';

import { CreateEngagementForm } from './createEngagementForm';

export function CreateEngagementModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: UseModalProps['onClose'];
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{lang.engagement.single.add.action}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CreateEngagementForm onClose={onClose} />
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
