import React, { useEffect, useState } from 'react';
import { FaWandMagicSparkles } from 'react-icons/fa6';
import { useParams } from 'react-router-dom';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { ComparableEngagements, Engagement } from '@keyops-cep/api-client';

import { apiClient } from '../../api/swagger-codegen-api-config';
import { BackBreadcrumb } from '../../components/CustomBreadcrumb';
import { CustomToast } from '../../components/CustomToast';
import GenericButton from '../../components/GenericButton';
import { GenericLink } from '../../components/GenericLink';
import Loading from '../../components/Loading';
import { useEngagement } from '../../hooks/useEngagement';
import { useTenant } from '../../hooks/useTenants';
import { CUSTOMER_UI_ENGAGEMENT_DETAILS } from '../../utils/external-routes';
import {
  runAIComparisons,
  sortComparableQuestionsAscending,
} from '../../utils/functions/compare.utils';
import { navigateToCustomerUi } from '../../utils/functions/navigate-to-customer-ui';
import { TENANTED_ENGAGEMENT_SINGLE_PAGE } from '../../utils/internal-routes';
import lang from '../../utils/lang';

import { ComparisonsTabs } from './components/comparisonsTabs';

export const EngagementsComparison: React.FunctionComponent = () => {
  const { addToast } = CustomToast();
  const [isLoading, setLoading] = useState<boolean>(true);

  const tenant = useTenant();
  const { engagement: currentEngagement } = useEngagement();
  const { comparableEngagementId } = useParams();
  const [isRunComparisonLoading, setRunComparisonLoading] = useState(false);

  // Retrieve the similar Engagement
  const [similarEngagement, setSimilarEngagement] = useState<Engagement>();

  // Retrieve the ComparableEngagements for these 2 Engagements
  const [comparableEngagements, setComparableEngagements] =
    useState<ComparableEngagements>();

  // Get the engagement comparable to the current one
  const handleGetSimilarEngagement = async () => {
    if (comparableEngagementId) {
      try {
        const result =
          await apiClient.engagementsApi.engagementControllerFindOne(
            comparableEngagementId
          );

        if (result.data) {
          setSimilarEngagement(result.data);
        }
      } catch (error) {
        addToast(lang.errors.default, `error`);
      }
    }
  };

  // Get the comparableEngagements related to these 2 engagements
  const handleGetComparableEngagements = async () => {
    if (comparableEngagementId && currentEngagement.id) {
      try {
        const result =
          await apiClient.comparableEngagementsApi.comparableEngagementsControllerGetOne(
            currentEngagement.id,
            comparableEngagementId
          );

        if (result.data) {
          // Sort ComparableQuestions by questions position
          setComparableEngagements({
            ...result.data,
            comparableQuestions: sortComparableQuestionsAscending(
              result.data?.comparableQuestions,
              currentEngagement.id
            ),
          });
        }
      } catch (error) {
        addToast(lang.errors.default, `error`);
      }
    }
  };

  useEffect(() => {
    handleGetSimilarEngagement();
    handleGetComparableEngagements();

    setLoading(false);

    // eslint-disable-next-line
  }, [currentEngagement, comparableEngagementId]);

  // Re-run the comparisons for the given comparableEngagements
  const handleRunAIComparisons = async (comparableEngagementsId: string) => {
    setRunComparisonLoading(true);
    try {
      await runAIComparisons(comparableEngagementsId);
      setRunComparisonLoading(false);
      addToast(
        lang.engagement.single.comparisonAnalysis.successfulComparisons,
        `success`
      );
    } catch (error) {
      addToast(lang.errors.default, `error`);
      setRunComparisonLoading(false);
    }
  };

  const getRenderedView = () => {
    return (
      <Container maxWidth="1600px" width="100%" margin="auto">
        <BackBreadcrumb
          link={TENANTED_ENGAGEMENT_SINGLE_PAGE.replace(
            ':tenantId',
            currentEngagement.tenantId
          ).replace(':engagementId', currentEngagement.id)}
        />
        <Box
          margin={5}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Heading as="h1" fontSize={24} lineHeight={'32px'} textAlign="left">
            Comparing {currentEngagement?.name} with {similarEngagement?.name}
          </Heading>
          {/* Button to generate comparisons */}
          <GenericButton
            type={'button'}
            text={
              lang.engagement.single.comparisonAnalysis.regenerateComparisons
            }
            leftIcon={<FaWandMagicSparkles />}
            onClick={() =>
              comparableEngagements &&
              handleRunAIComparisons(comparableEngagements?.id)
            }
            isLoading={isRunComparisonLoading}
          />
        </Box>
        <Box margin={5} marginRight={0}>
          <Box pb={1}>
            <GenericLink
              mr={3}
              pr={3}
              text={currentEngagement.name}
              href={TENANTED_ENGAGEMENT_SINGLE_PAGE.replace(
                ':tenantId',
                currentEngagement.tenantId
              ).replace(':engagementId', currentEngagement.id)}
              borderRight={'solid black 0.5px'}
            />
            <GenericLink
              text={lang.engagement.single.preview}
              onClick={() => {
                navigateToCustomerUi(
                  CUSTOMER_UI_ENGAGEMENT_DETAILS.replace(
                    ':engagementId',
                    currentEngagement.id
                  )
                );
              }}
            />
          </Box>
          <Text>{currentEngagement?.displayTitle}</Text>
          <Text>{lang.engagement.single.comparableEngagement.themes}:</Text>
          <Text>{currentEngagement?.themes}</Text>
        </Box>

        <Box margin={5} mt={8} marginRight={0}>
          <Box pb={1}>
            <GenericLink
              mr={3}
              pr={3}
              text={similarEngagement?.name as string}
              href={TENANTED_ENGAGEMENT_SINGLE_PAGE.replace(
                ':tenantId',
                similarEngagement?.tenantId as string
              ).replace(':engagementId', similarEngagement?.id as string)}
              borderRight={'solid black 0.5px'}
            />
            <GenericLink
              text={lang.engagement.single.preview}
              onClick={() => {
                navigateToCustomerUi(
                  CUSTOMER_UI_ENGAGEMENT_DETAILS.replace(
                    ':engagementId',
                    similarEngagement?.id as string
                  )
                );
              }}
            />
          </Box>
          <Text>{similarEngagement?.displayTitle}</Text>
          <Text>{lang.engagement.single.comparableEngagement.themes}:</Text>
          <Text>{similarEngagement?.themes}</Text>
        </Box>

        {comparableEngagements && similarEngagement && (
          <ComparisonsTabs
            comparableEngagements={comparableEngagements}
            setComparableEngagements={setComparableEngagements}
            currentEngagementName={currentEngagement.name}
            comparableEngagementName={similarEngagement?.name}
          />
        )}
      </Container>
    );
  };

  if (!tenant || !currentEngagement || !similarEngagement || isLoading) {
    return <Loading />;
  }

  return getRenderedView();
};
