import React, { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Heading, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';

import { EngagementDto } from '../../api/dto/engagement.dto';
import { getTenantsByIds } from '../../api/functions/tenants.functions';
import { CustomBreadcrumb } from '../../components/CustomBreadcrumb';
import GenericButton from '../../components/GenericButton';
import Loading from '../../components/Loading';
import { useEngagements } from '../../hooks/useEngagements';
import { useTenant } from '../../hooks/useTenants';
import { ENGAGEMENTS, HOME } from '../../utils/breadcrumb-elements';
import { dateFormatter } from '../../utils/functions/common-utils';
import { HEALTHCHECK_PAGE } from '../../utils/internal-routes';
import lang from '../../utils/lang';
import { CreateEngagementModal } from '../Engagement/components/createEngagementModal';

import { EngagementsTable } from './components/engagementsTable';

//Build table logic
export type EngagementTableHead = {
  id: string;
  name: string;
  displayTitle?: string;
  resultsStatus: string;
  responseCountTarget?: number;
  surveySparrowId?: string;
  startDate?: Date;
  endDate?: Date;
  createdAt: Date;
  completedDate?: Date;
  tenantName: string;
  actions: string;
  // TODO: update when type is added to the Engagement entity
  // type: string;
};

interface EngagementTableData extends EngagementDto {
  tenantName: string;
}

export const buildEngagementTableColumns = () => {
  const columnHelper = createColumnHelper<EngagementTableHead>();
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: lang.engagement.all.headers.internalName,
    }),
    columnHelper.accessor('displayTitle', {
      cell: (info) => info.getValue(),
      header: lang.engagement.all.headers.fullName,
    }),
    // TODO: update when type is added to the Engagement entity
    // columnHelper.accessor('type', {
    //   cell: (info) => info.getValue(),
    //   header: lang.engagement.all.headers.type,
    // }),
    columnHelper.accessor('resultsStatus', {
      cell: (info) => info.getValue().toLocaleUpperCase(),
      header: lang.engagement.all.headers.resultsStatus,
    }),
    columnHelper.accessor('responseCountTarget', {
      cell: (info) => info.getValue(),
      header: lang.engagement.all.headers.expectedParticipants,
    }),
    columnHelper.accessor('createdAt', {
      cell: (info) => dateFormatter(info.getValue()?.getTime()),
      header: lang.engagement.all.headers.creationDate,
    }),
    columnHelper.accessor('startDate', {
      cell: (info) => dateFormatter(info.getValue()?.getTime()),
      header: lang.engagement.all.headers.startDate,
    }),
    columnHelper.accessor('endDate', {
      cell: (info) => dateFormatter(info.getValue()?.getTime()),
      header: lang.engagement.all.headers.endDate,
    }),
    columnHelper.accessor('completedDate', {
      cell: (info) => dateFormatter(info.getValue()?.getTime()),
      header: lang.engagement.all.headers.completedDate,
    }),
    columnHelper.accessor('tenantName', {
      cell: (info) => info.getValue(),
      header: lang.engagement.all.headers.clientName,
    }),
    columnHelper.accessor('surveySparrowId', {
      cell: (info) => info.getValue(),
      header: lang.engagement.all.headers.surveySparrowID,
    }),
    columnHelper.accessor('actions', {
      cell: (info) => (
        <Text
          display="flex"
          justifyContent="space-between"
          alignContent="space-between"
        >
          <FaEdit />
          {/* TODO: add a delete action here */}
          <MdDelete />
        </Text>
      ),
      header: lang.engagement.all.headers.actions,
    }),
  ];

  return columns;
};

async function getEngagementTenantNames(
  engagements: EngagementDto[]
): Promise<EngagementTableData[]> {
  const uniqueTenantIds = [...new Set(engagements.map((e) => e.tenantId))];
  const tenants = await getTenantsByIds(uniqueTenantIds);
  if (!tenants || !tenants.data) return [];
  const tenantNameMap = Object.fromEntries(
    tenants.data.map((t) => [t.id, t.name])
  );
  return engagements.map((e) => ({
    ...e,
    tenantName: tenantNameMap[e.tenantId],
  }));
}

const buildEngagementTableData = (engagements: EngagementTableData[]) => {
  const data: EngagementTableHead[] = [];
  engagements.forEach((engagement) =>
    data.push({
      id: engagement.id,
      name: engagement.name,
      displayTitle: engagement.displayTitle,
      resultsStatus: engagement.resultsStatus,
      // TODO: ad-board - Replace par engagement.type when ready
      // type: 'Survey',
      // TODO: update when status handling is revised
      responseCountTarget: engagement.responseCountTarget,
      surveySparrowId: engagement.surveySparrowId,
      createdAt: new Date(engagement.createdAt),
      startDate: engagement.startDate
        ? new Date(engagement.startDate)
        : undefined,
      endDate: engagement.endDate ? new Date(engagement.endDate) : undefined,
      completedDate: engagement.completedDate
        ? new Date(engagement.completedDate)
        : undefined,
      tenantName: engagement.tenantName,
      actions: '<p>test<p>',
    })
  );

  return data;
};

export const Engagements: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const tenant = useTenant();
  const { data: engagements, isLoading } = useEngagements(tenant?.id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tableData, setTableData] = useState<EngagementTableHead[]>([]);
  const [isTenantsLoading, setIsTenantsLoading] = useState(false);

  useEffect(() => {
    if (engagements && engagements?.length > 0) {
      setIsTenantsLoading(true);
      getEngagementTenantNames(engagements).then(
        (engagementsWithTenantNames) => {
          const data = buildEngagementTableData(engagementsWithTenantNames);
          setTableData(data);
          setIsTenantsLoading(false);
        }
      );
    }
  }, [tenant, engagements]); // eslint-disable-line react-hooks/exhaustive-deps

  const breadcrumbList = [HOME, ENGAGEMENTS];

  return (
    <>
      <CustomBreadcrumb list={breadcrumbList} />
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        paddingLeft={5}
        paddingRight={5}
      >
        <Heading as="h1" fontSize={24} lineHeight={'32px'}>
          {isLoading
            ? lang.engagement.all.title
            : lang.engagement.all.title + (tenant ? ` for ` + tenant.name : ``)}
        </Heading>
        {tenant ? (
          <Stack direction={'row'} alignItems={'baseline'}>
            <GenericButton
              type="button"
              text={lang.engagement.single.add.action}
              onClick={onOpen}
            />
            {/* Access to the healthcheck statuses page */}
            <GenericButton
              type="button"
              text={`Healthcheck page`}
              onClick={() => navigate(HEALTHCHECK_PAGE)}
            />
          </Stack>
        ) : undefined}
      </Stack>

      {isLoading || isTenantsLoading ? (
        <Loading />
      ) : (
        <EngagementsTable data={tableData} tenantId={tenant?.id} />
      )}

      <CreateEngagementModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
