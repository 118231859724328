// TODO use generate code instead of these manually defined routes
export const GET_ENGAGEMENTS_ENDPOINT = '/survey-engagements';
export const GET_ENGAGEMENT_ENDPOINT = '/survey-engagements/:engagementId';

export const POST_ENGAGEMENT_ENDPOINT = '/survey-engagements';
export const UPDATE_ENGAGEMENT_ENDPOINT = '/engagements/:engagementId';
export const UPDATE_ENGAGEMENT_STATUS_ENDPOINT =
  '/engagements/:engagementId/update-status';
export const UPDATE_ENGAGEMENT_HIDE_DEMOGRAPHICS_ENDPOINT =
  '/engagements/:engagementId/hide-demographics';
export const GET_ENGAGEMENT_ANALYSIS =
  '/survey-engagements/:engagementId/analysis';
export const GET_ENGAGEMENT_QUESTIONS =
  '/survey-engagements/:engagementId/questions';
export const GET_ENGAGEMENT_RESPONSES =
  '/survey-engagements/:engagementId/responses';

export const REFRESH_SURVEY_ENDPOINT =
  '/survey-engagements/:engagementId/refresh-survey';
export const BULK_UPDATE_DEMOGRAPHICS_ENDPOINT =
  '/engagements/:engagementId/respondent-demographics';
